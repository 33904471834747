.banner {
  // margin-top: -7rem;
  // padding: 9rem 0 5rem;
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.741),
      rgba(68, 68, 68, 0.741)
    ),
    url("../images/banner/bannerbg.png");
  // background-image: url("../images//banner/bannerbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 106vh;
  width: 100%;
  display: flex;
  align-items: center;

  .banner-left {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .banner-heading {
      font-size: 2.87rem;
      font-weight: 700;
      color: $white;
      line-height: 3.5rem;
      font-family: $primary-font;

      @media #{$xs-layout} {
        font-size: 1.72rem;
        line-height: 2.53rem;
        margin-top: 5rem;
        // text-align: center;
      }
    }

    .banner-text {
      line-height: 1.9rem;
      color: $white;
      width: 89%;
      margin-bottom: 1.5rem;

      @media #{$xs-layout} {
        font-size: 1rem;
        width: 100%;
        line-height: 1.5rem;
        // text-align: center;
      }
    }
    .btn-div {
      @media #{$xs-layout} {
        display: flex;
        // justify-content: center;
        align-items: center;
      }
    }

    .banner-btn {
      width: fit-content;
      border: none;
      border-radius: 0;
      background-color: $primary-color;
      color: $white;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.85rem 1.52rem;
      font-family: $secondary-font;
      letter-spacing: 1px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      box-shadow: rgb(255 255 255 / 20%) 0px 7px 29px 0px;

      transition: all 0.3s ease-in;

      &:hover {
        transform: scale(1.1);
        // box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px 10px, rgba(255, 255, 255, 0.3) 0px 30px 60px 10px;
      }

      .banner-btn-icon {
        font-weight: 600;
        font-size: 1.32rem;
      }
    }
  }

  .banner-right {
    @media #{$xs-layout} {
      margin: 2rem 0;
      display: flex;
      justify-content: center;
    }

    .banner-img-box {
      width: 67%;
      float: right;

      @media #{$xs-layout} {
        // width: 60%;
        // float: none;
        display: none;
      }
    }
  }
}
