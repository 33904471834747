.newsletter_wrapper {
    background-color: $black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &:after{
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        height: 100px;
        width: 100px;
        background-image: url('../images/elements/orange.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .couter-content {
        text-align: center;

        .counter-number {
            line-height: 2.5rem;
            font-size: 2.5rem;
            margin-bottom: 0.25rem;
            font-family: $secondary-font;
            font-weight: 500;
            color: $white;
            text-align: center;
        }
        p {
            // font-family: $secondary-font;
            // font-weight: 500;
            // line-height: 1.75rem;
            width: 90%;
            // font-size: 1rem;
            color: $white;
            margin: auto;
            text-align: center;
        }
    }
}
