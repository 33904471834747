.xclub-section {
  padding: 4rem 0;
  background-image: linear-gradient(
      to right,
      rgba(277, 96, 6, 0.7),
      rgba(277, 96, 6, 0.7)
    ),
    url("../images/club/secbg.jpg");
  background-color: rgba(190, 162, 104, 0.7);
  // .container{
  //     background-image: linear-gradient(to right, rgba(135, 41, 52,.87), rgba(135, 41, 52,.87)), url('../images/club/secbg.jpg');
  //     background-color: rgba(190, 162, 104,.7);
  //     // padding: 4rem 2rem;
  // }

  .club-content {
    h3 {
      color: $white;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      // font-family: $secondary-font;
    }
    p {
      text-align: center;
      color: $white;
      font-size: 1.1rem;
      line-height: 1.75rem;
      font-family: $secondary-font;
    }
  }
  .club-btn {
    // border: 1px solid red;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      background-color: $white;
      color: $primary-color;
      padding: 1.35rem 3rem;
      border-radius: 0.35rem;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 0.1rem;
      transition: all 0.3s;
      // font-family: $secondary-font;

      &:hover {
        color: $white;
        background-color: $primary-color;
        box-shadow: 20px 10px 30px rgba(255, 255, 255, 0.37);
      }
    }
  }
}

.xclub-page-section {
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.81),
      rgba(68, 68, 68, 0.81)
    ),
    url("../images/club/clubbg.jpg");
}

.xclub-banner {
  padding: 6rem 0;
  background-color: $black;

  @media #{$lg-med-layout}{
    padding: 4rem 0;
  }

    .x-clubcontainer{
      background-image: linear-gradient(105deg, transparent 50%, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 50%), url('../images/club/formbg.jpg');

      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      border-radius: 4px;

      @media #{$lg-med-layout}{
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), url('../images/club/formbg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

      }
    }
  width: 100%;

  // @media #{$lg-md-layout} {
  //   padding: 35px 0;
  // }

  .banner-left {
    .banner-heading {
      font-size: 1.75rem;
      font-weight: 700;
      color: #fff;
      margin-bottom: 1rem;
      text-transform: capitalize;
      line-height: 2.5rem;
    }

    .banner-text {
      font-size: 1.1rem;
      line-height: 1.75rem;
      color: $white;
      font-style: normal;
      font-weight: 400;
    }
    @media #{$lg-med-layout} {
      display: none;
    }
  }
}

.enquiry {
  max-width: 600px;
  float: right;
  margin: 1rem;
  padding: 30px 20px;
  background-color: transparent;

  @media #{$lg-med-layout}{
    margin: auto;
    float: none;
  }
 
  h3 {
    font-size: 2rem;
    // line-height: 1.95rem;
    padding-inline-start: .95rem;
    background-image: linear-gradient(to right, $primary-color, $ternary);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 700;
    margin-bottom: 1rem;

    @media #{$xs-layout}{
      text-align: center;
      padding: 0;
    } 
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;

    .form-control {
      border-radius: 0;
      outline: none;
      height: 55px;
      margin: 0.99rem 0 0;
      border-radius: 2px;
      border: none;
      border-bottom: 3px solid transparent;
      background-color: rgba(255, 255, 255, 0.5);

      @media #{$xs-layout}{
        background-color: rgba(255, 255, 255, 0.75);

      } 

      &:focus{
        border-bottom: 3px solid $primary-color;
        box-shadow: none;
      }
    }
    button {
      width: 100%;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      padding: 0.5rem 0;
      background-color: $primary-color;
      color: $white;
      border: none;
      outline: none;
    }
  }
}
