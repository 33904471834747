.page-navigation {
    margin: 1.5rem 0 1rem;
    // border: 1px solid red;

    ul {
        display: flex;
        list-style: none;
        padding: 0;

        li {
            position: relative;

            &:not(:last-child) {
                &:before {
                    position: absolute;
                    content: "/";
                    top: 2px;
                    width: 4px;
                    height: 8px;
                    color: $white;
                    right: -1px;
                }
            }
            a {
                text-decoration: none;
                color: $white;
                font-weight: 400;
                font-family: $secondary-font;
                padding: 0.5rem;
                font-size: 1.1rem;

                &.currentlink{
                    letter-spacing: 1px;
                }
            }
        }
    }
}
