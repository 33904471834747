.globals {
    padding: 3rem 0;
    // position: relative;
    // background-color: $white !important;

    .sec-heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            font-size: 2.5rem;
            color: $black;
            line-height: 2.5rem;
            text-align: center;
            font-weight: 700;
            position: relative;
            margin-bottom: 1rem;

            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 75px;
                bottom: -10px;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $primary-color;
            }
        }
        p {
            // font-size: 0.9rem;
            text-align: center;
            width: 70%;
            color: $grey;
            // font-family: $secondary-font;

            @media #{$xs-med-layout}{
                font-size: .8rem;
                width: 100%;
            }
        }
    }

    .slick-slide{
        display: flex;
        justify-content: center;
    }

    .slick-arrow{
        display: none !important;
    }

    .img-box {
        margin: auto;

        .img-link {
            width: 100%;
            border-radius: 10rem;
            height: 10rem;
            cursor: pointer;
            display: block;
            margin: 0;
            // padding: 0;
            position: relative;
            border: none;
            outline: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: grayscale(1);
                border-radius: 10rem;
            }
            .img_caption {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 20%);
                color: $primary-color;
                text-transform: uppercase;
                font-size: 1.7rem;
                font-weight: bold;
                text-align: center;
                opacity: 0;
                border: none;
                outline: none;
                transition: all 0.5s;
                backface-visibility: hidden;
            }

            &:hover {
                img{
                    filter: blur(2px) grayscale(1);
                }
                .img_caption {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}


.key-sectors{
    position: relative;

    &:after{
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        height: 100px;
        width: 100px;
        background-image: url('../images/elements/orange.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media #{$xs-layout}{
            display: none;
        }
    }

}

.img-div{
    // border: 2px solid red;
    position: relative;
    height: 18rem;

    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
        filter: grayscale(1);
    }

    .img_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        color: $white;
        text-transform: uppercase;
        font-size: 1.17rem;
        font-weight: bold;
        text-align: center;
        opacity: 0;
        border: none;
        outline: none;
        transition: all 0.5s;
        backface-visibility: hidden;
        background-color: rgba(0, 0, 0,.5);
        padding: 1rem;
        border-radius: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        // img{
        //     filter: blur(5px) grayscale(50%);
        // }
        .img_caption {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }

}