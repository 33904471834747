.side-drawer{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 50%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .26);

    @media #{$xs-med-layout}{
        width: 70%;
    }
}

.side-drawer{
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    max-width: 320px;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
    overscroll-behavior: contain;
    transition: 0.5s ease;
    background: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
}

.category-filter-icon {
    display: none;
  
    .filter-slide{
      width: fit-content;
      color: #5F6368;
      background-color: #dfe0e1;
      border-radius: 100px;
      height: 40px;
      width: 40px;
      padding: 10px;
      line-height: 13px;
  
      .filter-icon{
        font-size: 20px;
        font-weight: bold;
        transition: all .5s;
  
        &:hover{
          transform: rotate(270deg);
        }
      }
  
      i{
        margin: 0 .3rem;
      }
      span{
        letter-spacing: 1.1px;
        font-family: $primary-font;
      }
    }
  
    @media #{$lg-med-layout} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  