.contact-section {
    // background-image: linear-gradient(to right, rgba(68, 68, 68, 0.41), rgba(68, 68, 68, 0.41)),
    //     url("https://investmentwp.com/wpbakery/wp-content/themes/investment/assets/images/about-career.jpg");
    margin-top: -12rem;
    padding: 3rem 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    @media #{$xs-layout} {
        margin-top: -14rem;
        padding: 7rem 0 0;
    }

    .page-img {
        width: 100%;
        max-height: 650px;
        overflow: hidden;

        @media #{$xs-med-layout} {
            min-height: 350px;
        }

        .page-main-img {
            object-fit: cover;

            @media #{$xs-med-layout} {
                object-fit: fill;
                width: 100%;
                height: 350px;
            }
        }
    }

    .page-heading {
        position: absolute;
        bottom: -1.5rem;
        left: 50%;
        transform: translate(-50%, -50%);
        // background-color: red;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.25rem;

        @media #{$xs-med-layout} {
            bottom: -2.5rem;
        }

        h1 {
            font-size: 2.5rem;
            font-family: $primary-font;
            font-weight: 600;
            line-height: 2rem;

            @media #{$xs-med-layout} {
                font-size: 1.5rem;
            }
        }
        p {
            font-size: 1.5rem;
            font-family: $secondary-font;

            @media #{$xs-med-layout} {
                font-size: 0.85rem;
                font-weight: 600;
            }
        }

        h1,
        p {
            background-color: $white;
            color: $black;
            text-align: center;
            width: fit-content;
            padding: 0.25rem 0.5rem;
            border-radius: 0.6rem;
            margin-bottom: 0;
            border: none;

            @media #{$xs-med-layout} {
                background-color: transparent;
            }
        }
    }
}

.contact-info {
    background-color: $white;
    padding: 3rem 0;
}
.contact-content {

        @media #{$xs-layout}{
            display: flex;
            flex-direction: column-reverse;
        }

    .contact-text-section {
        .contact-heading {
            font-size: 1rem;
            color: $primary-color;
            font-family: $primary-font;
            font-weight: 600;
        }
        .contact-head {
            color: $black;
            font-size: 1.5rem;
        }
        .contact-list {
            list-style-type: none;

            .contact-lists {
                margin: 0 0 0.7rem;
                padding: 0.35rem;
                display: flex;
                align-items: center;

                @media #{$xs-layout} {
                    margin: 0 0 15px;
                    padding: 12px;
                }

                .contact-icon {
                    color: $primary-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px !important;
                    padding: 1rem;
                    height: 60px;
                    margin-right: 1.5rem;
                    border-radius: 100%;
                    background-color: #f3eaeb;

                    svg {
                        font-size: 2.5rem;
                    }
                }

                .contact-text {
                    .contact-tect-heading {
                        color: $primary-color;
                        text-transform: capitalize;
                        font-weight: bold;
                        letter-spacing: 1px;
                        font-size: 1.1rem;
                        line-height: 20px;
                    }
                    // p {
                    //     line-height: 1.52rem;
                    // }
                }
            }
        }
    }

    .contact-form-section {
        .contact-auth-section {
            background-color: #fff;
            margin: 0 0 20px;
            padding: 1rem;
            display: flex;
            border-radius: .51rem;
            align-items: center;
            box-shadow: 1px 1px 4px #d3d3d3;
        }
        input,
        select {
            height: 45px;
            // border: 1px solid $primary-color;
        }
        textarea {
            height: 110px;
        }
        .form-control {
            margin: 0;
            padding: 0 15px;
            max-width: 100%;
            width: 100%;
            // border: none;
            box-shadow: none;
            vertical-align: middle;
            margin: 0.3rem 0;
            font-size: 15px;
            transition: border-color 0.5s ease;
        }

        .contact-social-list {
            list-style: none;
            display: flex;
            padding: 0.2rem 1rem;
            background-color: #f4f4f4;
            margin: 0 0 20px;
            padding: 17px;
            display: flex;
            align-items: center;

            a {
                font-size: 28px;
                color: #777;
                padding: 0.2rem 0.5rem;

                .social-icons__facebook,
                .social-icons__instagram,
                .social-icons__linkedin,
                .social-icons__twitter {
                    transition: all 0.9s;
                }

                .social-icons__facebook:hover {
                    color: #1877f2;
                }
                .social-icons__instagram:hover {
                    color: #e4405f;
                }

                .social-icons__linkedin:hover {
                    color: #0a66c2;
                }

                .social-icons__twitter:hover {
                    color: #1da1f2;
                }
            }
        }
    }
}
