.privacy-page-section {
    background-image: linear-gradient(to right, rgba(68, 68, 68, 0.61), rgba(68, 68, 68, 0.41)),
        url("../images/services/page-bg.jpg");   
}

.privacy-content{
    h3{
        font-weight: 600;
        color: $primary-color;
        margin-top: 1rem;
    }
    li{
        font-size: 1.1rem;
        line-height: 1.75rem;
        font-family: $secondary-font;
        color: $grey;
        margin: .2rem 0;
    }
}