.subscription-section {
    padding: 3rem 0;
    background-color: $black;
    font-weight: 500;

    .subscription-content {
        h2 {
            color: $primary-color;
            font-size: 1.72rem;
        }
        p {
            color: $white;
            width: 90%;
            color: #f3f3f3;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.75rem;
            font-family: $secondary-font;
        }
    }

    .subs-form {
        width: 100%;
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;

        .subs-btn {
            border: none;
            background-color: $primary-color;
            color: $white;
            border-radius: 0.25rem;
            font-size: 1.72rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 0.6rem 1.52rem;
        }
    }

    .subs-inputs {
        max-width: 100%;
        // border: 1px solid red;

        .form-control {
            background: transparent;
            border: none;
            border-radius: 0;
            border-bottom: 2px solid $ternary;
            height: 55px;
            color: $white;
            font-family: $secondary-font;
            font-size: 1.2rem;
            outline: none;

            &::placeholder {
                color: $grey;
                font-size: 1rem;
            }
        }

        select {
            option {
                color: $black !important;
            }
        }

        .subs-btn {
            background-color: $primary-color;
            color: $white;
            border-radius: 0.25rem;
            padding: 0.5rem 1rem;
            border: none;
            outline: none;
            width: 100%;
            margin: 1rem 0;
        }
    }
}
