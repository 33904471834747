.location-section {
    padding: 5rem 0;




    .location-lists {
        margin-top: 2rem;

        .location-box {
            border: 1px solid #d3d3d3;
            box-shadow: 1px 1px 20px #d3d3d3;
            border-radius: 1.5rem;
            padding: 0.5rem;

            .img-box {
                width: 100%;
                height: 200px;
                overflow: hidden;
            }

            .loc-img {
                border-radius: 1rem;
            }
        }
    }
}


.loc-col {
    // border: 1px solid red;
    display: flex;
    justify-content: center;
}

.region-div {
    border: 1px solid #d3d3d3;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    width: fit-content;
    max-width: 80%;
    background-color: #fff;
    box-shadow: 1px 1px 20px #d3d3d3;
    position: absolute;
    bottom: -38px;

    @media #{$xs-layout} {
        border-radius: .25rem;
        padding: 0.5rem;
        max-width: 94%;
        bottom: -58px;
    }

    .location-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        list-style: none;

        @media #{$xs-layout} {
            justify-content: space-evenly;
            padding-left: 0;
        }

        @media #{$xs-med-layout} {
            border: none;
        }

        li {
            position: relative;
            padding: 0 1rem;

            @media #{$xs-med-layout} {
                padding: 0 .5rem;
            }

            &:not(:last-child) {
                border-right: 2px solid $ternary;

                // &:nth-child(1),
                // &:nth-child(2),
                // &:nth-child(3),
                // &:nth-child(4) {
                // border-left: 2px solid $ternary;
                border-right: 2px solid $ternary;

                @media #{$xs-med-layout} {
                    border: none;
                }
            }
        }

        .location-input {
            display: none;
        }

        .radio-label {
            font-family: $primary-font;
            // color: $black;
            color: #808080;
            font-weight: 600;
            font-size: 1.25rem;
            text-transform: uppercase;
            cursor: pointer;
            line-height: 1.75rem;
            transition: all 0.3s ease;
            // opacity: .7;

            @media #{$xs-med-layout} {
                font-size: .75rem;
                line-height: .5rem;
            }

            &.checked {
                opacity: 1;
                color: $primary-color;
                transform: scale(1.05);
                border-bottom: 2px solid $primary-color;
            }

            &:hover {
                color: $primary-color;
                transform: scale(1.05);
            }
        }
    }
}