.main-header {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  // background: rgba(135, 41, 52,.62);
  background: transparent;
  z-index: 50;

  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
  // border-bottom: 1px solid #d3d3d0;

  @media #{$xs-med-layout} {
    height: 3.5rem;
    padding: 2rem 0;
  }
}

.main-header.active {
  background: $white;
  background: rgba(0,0,0,.57);

  // .nav-links a {
  //   color: $black;
  // }
}

.topbar-container {
  background-color: $primary-color;
  padding: 0.5rem 1rem;
  color: #fff;

  .topbar-area {
    display: flex;
    justify-content: space-between;

    @media #{$xxs-md-layout} {
      align-items: center;
      flex-direction: column-reverse;
    }
  }
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
    padding: 2rem 2rem;
  }
}

@media (min-width: 991px) {
  .main-header {
    justify-content: space-between;
    padding: 1rem 6rem;
  }
}

@media (max-width: 991px) {
  .main-header {
    display: none;
  }
}
