.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  padding: 1rem 1.5rem;

  @media #{$lg-md-layout}{
    padding: 1rem 1.25rem;
  }
}

.nav-links a {
  color: $white;
  text-decoration: none;
  padding: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.8;
  font-family: $primary-font;
  transition: .5s ease;
  position: relative;

 

  &:hover {
    color: $white;
    &:after {
      transform: scaleX(1);
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: $white;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: center;
    transform-origin: center;
    transition: 0.3s ease;
  }
}

@media (max-width: 1024px) {
  .nav-links a {
    padding: 0.35rem;
    font-size: 1rem;
  }
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  opacity: 1;
  // color: $ternary;
}

@media (min-width: 991px) {
  .nav-links {
    flex-direction: row;
  }
}

@media (max-width: 991px) {
  .nav-links {
    display: none;
  }
}
