.service-list-page-section {
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.861),
      rgba(68, 68, 68, 0.741)
    ),
    url("../images/services/page-bg.jpg");
}

.service-list {
  background-color: $black;
  padding: 4rem 0;

  .service-box {
    background-color: $white;
    cursor: pointer;
    position: relative;
    padding: 40px 30px 40px;
    border-radius: 10px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    // max-height: 495px;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 2px 2px 10px 0px #bea268;
    }

    .icon-box {
      width: 5rem;
      height: 5rem;
      background-color: $primary-color;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .icons {
        color: $white;
        font-size: 2.73rem;
      }
    }

    .content-box {
      margin-top: 1rem;

      h3 {
        font-weight: 600;
        font-size: 1.52rem;
        line-height: 2rem;
      }
      // p {
      //   font-family: $secondary-font;
      //   font-size: 1.1rem;
      //   font-weight: 500;
      //   color: $grey;
      //   line-height: 1.75rem;
      // }
    }
    .link-box {
      a {
        text-decoration: none;
        color: $primary-color;
        font-weight: 600;
        font-family: $secondary-font;
      }
    }
  }
}

.stakeholder-section {
  .dashboard-wrapper {
    // .stake-para {
    //   font-family: $secondary-font;
    //   color: $grey;
    //   font-size: 1rem;
    //   line-height: 1.595rem;
    // }
    .nav-tabs {
      border: none;
      margin-bottom: 1rem;

      .nav-item {
        height: auto;
        border: none;

        .nav-link {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.25rem;
          color: $black;
          margin-bottom: 0;
          border: none !important;
          border-bottom: 0.51px solid $grey !important;
          border-radius: 0 !important;
          position: relative;
          padding: 12px 1rem;

          &.active {
            color: $primary-color;
            background-color: transparent !important;
            border-bottom: 2px solid $primary-color !important;
            // border: none !important;

            &:after {
              position: absolute;
              content: "";
              height: 4px;
              background: $primary-color;
              width: 100%;
              bottom: -1px;
              left: 0px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .dashboard-content {
    .parent-item {
      h4 {
        font-family: $secondary-font;
        color: $ternary;
        font-style: normal;
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 1.5rem;
        margin-bottom: 0.15rem;
      }
      // p {
      //   font-family: $secondary-font;
      //   color: $grey;
      // }
    }
  }
}

.servicepage2_wrapper {
  .service-small-list {
    ul {
      list-style: none;
      padding: 0;
    }
    .service-mobile-list {
      width: 100%;
      height: auto;
      padding: 1rem;
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      top: 100px;
      background-color: $white;

      @media #{$lg-med-layout} {
        position: fixed;
        width: 300px;
        height: 100%;
        z-index: 454;
        transform: translateX(-120%);
        transition: transform 0.2s ease;
        overflow-y: scroll;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
        top: 0;
      }
    
      &.active {
        transform: translateX(0);
      }
    }
    // width: 100%;
    // height: fit-content;
    // position: sticky;
    // top: 100px;

    .service-menu-items {
      height: 90%;

      .service-menu-item {
        border: 1px solid #ddd;
        margin-bottom: 10px;
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;

        // &:hover {
        //   box-shadow: 5px 8px 10px rgb(0 0 0 / 25%), 0 -1px 1px rgb(0 0 0 / 13%);
        // }

        .nav-link {
          padding: 0;
          display: flex;
          align-items: center;

          &.active,
          &:hover {
            box-shadow: 5px 7px 8px rgb(0 0 0 / 25%),
              0 -1px 1px rgb(0 0 0 / 13%);

            .icon {
              color: $primary-color;
            }
          }

          i,
          .icon {
            float: left;
            width: 58px;
            min-width: 58px;
            min-height: 58px;
            margin-right: 17px;
            text-align: center;
            background: #eee;
            font-size: 24px;
            color: $black;
            display: flex;
            align-items: center;
            padding: 0 17px;
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
          }
          span {
            padding: 10px 0;
            min-height: 58px;
            position: relative;
            display: block;
            color: $black;
            font-size: 1rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            transition: all 0.2s ease-in-out;

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  .service-quote {
    background: #eee;
    padding-left: 60px;
    padding-right: 20px;
    border-top: 1px solid #e5e5e5;
    font-size: 15px;
    padding-top: 26px;
    padding-bottom: 10px;

    &::before {
      content: "";
      width: 27px;
      height: 24px;
      // background: url(../images/quote.png) no-repeat 0 0;
      display: inline-block;
      position: absolute;
      top: 30px;
      left: 34px;
    }

    p {
      line-height: 25.6px;
      font-weight: 600;
    }
  }

  .servicepage-img {
    margin: 0 0 1.5rem;
    // height: 400px;
    width: 100%;
    overflow: hidden;

    .img-fluid {
      width: 100%;
      height: 100%;
    }
  }
 

  .heading {
    font-size: 1.72rem;
    font-weight: 600;
    color: $primary-color;
    // text-transform: uppercase;
  }

  .content-row {
    margin-bottom: 1.5rem;

    // .desc {
    //   font-family: $secondary-font;
    //   font-size: 1.1rem;
    //   line-height: 1.8rem;
    //   color: $grey;
    // }
  }

  .service-list-data {
    li {
      font-family: $secondary-font;
      color: $black;
      font-weight: 500;
      position: relative;
      padding-left: 1.3em;
      margin: 0 0 5px;
      color: #333333da;
    }
  }

  .features-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    .feature-box {
      width: 31%;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      padding: 1rem;
      display: flex;
      gap: 1rem;
      cursor: pointer;
      box-shadow: 0px 0px 5px 0px rgba(28, 36, 51, 0.15);

      @media #{$lg-md-layout}{
        width: 48%;
      }

      @media #{$xs-layout}{
        width: 100%;
      }

      &:hover {
        transform: scale(1.07);

        h3 {
          color: $primary-color;
        }

        h2 {
          background-color: $primary-color;
          color: $white;
        }
      }

      h2 {
        font-size: 1.2rem;
        border-radius: 5rem;
        padding: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        color: $white;
        color: $primary-color;
        background-color: #e7d4d6;
        font-family: $secondary-font;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        color: $black;
      }
      p {
        margin-bottom: 0;
      }
      .content-text{
        ul{
          // list-style: none;
          padding: 0;

          li{
            padding-left: 0;
          }
        }
      }
    }
  }

  .advantages-list {
    margin: 2rem 0;

    .adv-box {
      display: flex;
      align-items: flex-start;

      p{
        // color: $grey;
        position: relative;
        padding-inline-start: 2.5rem;
        margin: .45rem 0;
        font-weight: 600;
        // font-size: 1.15rem;
        // line-height: 1.75rem;

        &:before{
          position: absolute;
          content: "";
          vertical-align: middle;
          top: 0;        
          left: 0;  
          background-image: url('../images/services/check.png');
          width: 2rem;
          height: 2rem;
    background-size: cover;
        }
      }
    }
  }

  .file-row {
    background: #ddd;
    padding-top: 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 1.9rem;
    justify-content: space-around;

    .content,
    .file {
      width: fit-content;
    }

    a {
      color: $white;
      font-weight: 600;
      line-height: 20px;
      display: inline-block;
      padding: 14px 25px;
      margin-right: 6px;
      background: $primary-color;
      margin-top: 5px;
      margin-bottom: 5px;
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      span {
        font-size: 17px;
        font-weight: bold;
        margin: 0 0.5rem 0 0;
        color: $primary-color;
      }
    }
  }

  .content-div {
    .img-div {
      width: 100%;
      height: 250px;
      overflow: hidden;
    }

    .service-name {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
    }

    li {
      font-weight: 500;
      position: relative;
      // padding-left: 1.3em;
      margin: 0 0 5px;
      color: #333333da;

      &:before {
        position: absolute;
        height: 20px;
        width: 20px;
        left: 0;
        top: 0;
        content: "";
        font-family: FontAwesome;
        font-size: 20px;
        color: $primary-color;
      }
    }
  }
}

.advantages {
  padding: 5rem 0 6rem;
  // background-image: linear-gradient(
  //     to right bottom,
  //     rgba(33, 33, 33, 0.8),
  //     rgba(66, 66, 66, 0.8)
  //   ),
  //   url("../images/services/advantages.jpg");
    background-color: $black;

  .adv-box {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
    padding: 1.95rem 2rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
    transition: transform 0.3s;
    cursor: pointer;
    height: 100%;

    &:hover {
      transform: translateY(-1.5rem) scale(1.03);

      // .adv-icon{
      //   color: $primary-color;
      // }

      h3{
        color: $primary-color;
      }
    }

    .adv-icon {
      font-size: 3.5rem;
      margin-bottom: 1rem;
      color: $black;
      // color: $primary-color;
    }

    h3 {
      font-size: 1.36rem;
      line-height: 1.99rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 1rem;
      color: $black;
      // color: $primary-color;

    }

    p {
      // font-family: $secondary-font;
      // font-size: 1.1rem;
      // line-height: 1.75rem;
      // color: $grey;
      margin-bottom: 0;
    }
  }
}


.service-resource-sec{
  background-color: $black;
  padding: 4rem 0;
}