.about_wrapper {
    // background-image: url("../images/mapdots.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    .adv-heading {
        margin-bottom: 1rem;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 75px;
            bottom: -5px;
            left: 0;
            background-color: $primary-color;
        }
    }
    .sub-text {
        // font-weight: 500;
        // color: $grey;
        // font-size: 1rem;
        width: 85%;
        // font-family: $secondary-font;
    }

    .adv-btn {
        background-color: $primary-color;
        border: none;
        // border-radius: 0.25rem;
        padding: 0.5rem 1.25rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    a {
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        font-family: $secondary-font;
        text-transform: uppercase;
        text-decoration: none;
    }
    .arrow-icon {
        color: $white;
    }
}

.advantage-box {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    margin: 0.5rem;
    // box-shadow: 0px 0px 5px 0px rgba(28, 36, 51, 0.15);
    transition: all 0.4s ease-in-out;
    // border-radius: 0.5rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;

    cursor: pointer;

    @media #{$xs-med-layout} {
        margin: 0.5rem auto;
    }

    .adv-icon {
        color: $primary-color;
        font-size: 3rem;
    }

    h6 {
        color: $black;
        font-family: $primary-font;
        font-size: 1.2rem;
        font-weight: 700;
        transition: all 0.3s ease-in-out;
        margin-top: 0.25rem;
    }

    // p {
        // color: $grey;
        // font-size: 0.99rem;
        // font-family: $secondary-font;
        // line-height: 1.5rem;
    // }

    &:hover {
        // transform: scale(1.05);
        box-shadow: 1px 5px 20px 0px rgba(37, 82, 91, 0.2);

        h6 {
            color: $primary-color;
        }
    }
}
