.navigation__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    // width: 100%;
    height: fit-content;
  }
  
  .main-navigation__menu-btn {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    cursor: pointer;
    outline: none;
  }
  
  .main-navigation__menu-btn .fa {
    font-size: 1.7rem;
    outline: none;
  }
  
  .main-navigation__title {
    color: $primary-color;
    margin: 0;
    overflow: hidden;
  }
  
  // .main-navigation__title a {
  //   text-decoration: none;
  //   font-size: 1.3rem;
  //   font-weight: 600;
  //   color: $primary-color;
  
  //   @media #{$lg-md-layout}{
  //   font-size: 1.13rem;
  //   padding: 0 .5rem;
  //   }
  // }
  
  .main-navigation__header-nav {
    display: none;
  }
  
  .main-navigation__drawer-nav {
    height: 100%;
  }
  
  @media (min-width: 991px) {
    .main-navigation__menu-btn {
      display: none;
    }
  
    .main-navigation__header-nav {
      display: block;
    }
  }
  
  .mobile-navbar-heading {
    display: none;
  
    @media #{$lg-med-layout} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:  .5rem 1rem;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 50;

      &.active {
        background: rgba(0,0,0,.77);
      }
    }
  
    .mobile-bottom-navigation {
      .action-btn {
        position: relative;
        font-size: 30px;
        color: #212121;
        padding: 10px;
        border: none;
        background-color: transparent;

        .mobile-menu-icon{
          color: $white;
          font-size: 2rem;
        }
      }
    }
  }
  
  .mobile-navbar {
    .mobile-navigation-menu {
      .menu-top {
        padding-bottom: 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .menu-title{
          margin: 0;
          font-family: $primary-font;
          text-decoration: none;
          font-size: 1.3rem;
          font-weight: 600;
          color: $primary-color;
          line-height: 25px;
        }
        .menu-close-btn{
          border: none;
          background-color: #fff;
        }
      }
  
      .mobile-menu-category-list {
        margin-bottom: 30px;
        list-style: none;
        padding: 0;
  
        .menu-category {
          border-bottom: 1px solid #ededed;
  
          .menu-title {
            display: block;
            color: rgba(13, 12, 10,.9);
            font-size: 1.1rem;
            font-weight: 600;
            padding: .75rem 0;
            margin-bottom: 0;
            font-family: $primary-font;
          }
  
          .accordion-menu {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: none;
            background-color: #fff;
  
            & > div {
              font-size: 16px;
            }
          }
          .submenu-category-list {
            margin-left: 10px;
            transition: 0.5s ease-in-out;
            list-style: none;
            padding: 0;
  
            .submenu-title {
              display: block;
              padding: .4rem 0;
              font-size: 1rem;
              color: $grey;
              font-weight: 500;
              font-family: $primary-font;
              font-family: $secondary-font;
            }
          }
        }
      }
    }
  }
  
  .mobile-social-container{
    list-style: none;
  }