.testimonial {
    padding: 4rem 0 2rem;

    .sec-heading{
        h2, p{
            color: $black;
        }
    }

    .single-testimonial {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 0 1rem;

        @media #{$xs-layout}{
            flex-direction: column;
        }

        .testimonial-img {
            display: flex;
            align-items: center;
            border-radius: 100%;
            width: 300px;
            overflow: hidden;
            justify-content: center;
            // width: 100%;
            flex: .7;

            @media #{$xs-layout}{
                width: 200px;
            }
    

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .testimonial-content{
            flex: 2.3;
            h5{
                margin-bottom: 0;
                font-weight: 600;
                line-height: 1rem;
                margin-bottom: .5rem;

                @media #{$xs-layout}{
                    text-align: center;
                }
            }
            span{
                font-size: .8rem;
                font-weight: 400;
                text-transform: uppercase;

                @media #{$xs-layout}{
                    text-align: center;
                }
            }
            p{
                font-size: .82rem;
                font-family: $ternary-font;
                line-height: 1.4rem;
                color: $grey;
                margin-top: .5rem;
                margin-bottom: 0;

                @media #{$xs-layout}{
                    text-align: center;
                }
            }
        }
    }

    .slick-arrow{
        display: none !important;
    }
}
