.investor-sec {
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.61),
      rgba(68, 68, 68, 0.41)
    ),
    url("../images/investors/secbg.jpg");
  background-position: center !important;
}

.investor-list {
  .sec-heading {
    margin-bottom: 1rem;
    p {
      text-align: center !important;
    }
  }
  .investor-box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .img-box {
      transition: all 0.2s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      //   border: 2px solid $ternary;

      &:hover {
        transform: scale(1.1);
        box-shadow: 2px 2px 10px 0px #bea268;
      }
    }
  }
}

.investor-type {
  min-height: 50vh;
  .investor-box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in;

    &:hover {
      transform: scale(1.1);
      box-shadow: 2px 2px 10px 0px #bea268;
    }

    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
      //   border: 2px solid $ternary;
    }

    .content-box {
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        color: $white;
        font-family: $secondary-font;
        font-size: 1.62rem;
        font-weight: 500;
      }
    }
  }
}


.investfileName{
  a{
    color: $primary-color;
    font-weight: 600;
    font-size: 1.43rem;
    line-height: 1.75rem;
    text-decoration: none;
    transition: all .2s ease;
    text-transform: capitalize;
    position: relative;

    &:before{
      position: absolute;
      content: '';
      top: 0.55rem;
      left: -1.25rem;
      width: .61rem;
      height: .61rem;
      background-color: $primary-color;
      transform: rotate(45deg);
    }

    &:hover{
      text-decoration: underline;
      // transform: scale(1.1);
      font-size: 1.5rem;
    }
  }
}