* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: "Source Sans Pro", sans-serif;
  font-family: $primary-font;
  transition: all 0.4s ease-in-out;
}

.mt-07 {
  margin-top: -7rem;
}

ul {
  margin-bottom: 0;
}

.text-bold {
  font-weight: 600 !important;
}

.text-red {
  color: red;
  font-family: $secondary-font;
  font-weight: 600;
}

a {
  text-decoration: none;
  cursor: pointer;
}
p {
  color: $grey;
  font-size: 1.1rem;
  line-height: 1.75rem;
  font-family: $secondary-font;
  font-weight: 400;
}

.no-data {
  text-align: center;
  padding: 10px 0;

  img {
    max-width: 300px;
  }

  p {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 992px) {
  .desktop-device {
    display: none !important;
  }
}
