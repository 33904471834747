.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.submit-btn {
    border: none;
    background-color: $primary-color;
    font-family: $primary-font;
    width: 100%;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 2rem;
    padding: 0.2rem 0;
}
