.service-comp{
    padding: 3rem 0;

    .section-heading{
        display: flex;
        justify-content: center;

        h2{
            font-size: 2.5rem;
            color: $black;
            line-height: 2.5rem;
            text-align: center;
            font-weight: 700;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 75px;
                bottom: -10px;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $primary-color;
            }
        }
    }

    .service-box{
        height: 100%;
        padding: 0 30px 25px;
        box-shadow: 20px 10px 30px rgba(16, 0, 71, 0.07);
        background: #ffffff;
        // border-radius: 5px;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;

        .service-box-icon{
            width: 5rem;
            height: 5rem;
            border-radius: 0 0 50% 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f3eaeb;
        }

        .service-icon{
            font-size: 2.5rem;
            color: $primary-color;
            text-align: center;
            display: inline-block;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
        }

        .service-box-content{
            margin-top: .5rem;
            h4{
                a{
                    text-decoration: none;
                    // text-transform: capitalize;
                    color: $black;
                    font-size: 1.35rem;
                    line-height: 1.5rem;
                    font-style: $secondary-font;
                    font-weight: 500;
                }
            }
            p{
                // color: $grey;
                // font-size: .85rem;
                // line-height: 1.34rem;
                // font-weight: 500;
                // font-family: $secondary-font;
                // margin-bottom: 0;

            }

            .explore-btn{
                color: $primary-color;
                font-size: 1rem;
                font-weight: 600;
                font-family: $secondary-font;
            }
        }

        &:hover{
            background-color: $primary-color;

            .service-box-icon{
                background: $white;
            }

            .service-box-content{
                margin-top: .5rem;
                h4{
                    a{
                        color: $white;
                    }
                }
                p{
                    color: $white;
                }
                .explore-btn{
                    color: $white;
                }
            }
        }
    }
}