.about-content {
    padding: 3rem 0;

    .sec-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // h2{
        //     font-size: 1rem;
        //     text-transform: uppercase;
        //     color: $black;
        // }

        h3 {
            font-size: 2rem;
            margin-bottom: 2rem;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 75px;
                bottom: -5px;
                left: 5px;
                background-color: $primary-color;
            }
        }
    }
    .sec-img {
        overflow: hidden;
        border-radius: 3rem;

        img {
            object-fit: scale-down;
        }
    }
}

.vision-sec {
    padding: 3rem 0;
    background-color: $white;

    .sec-img {
        // width: 100%;
        // height: 400px;
        // border: 1px solid red;
        overflow: hidden;
        border-radius: 3rem;

        img {
            object-fit: scale-down;
        }
    }

    .sec-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            font-size: 2rem;
            margin-bottom: 2rem;
            position: relative;
            color: $black;

            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 75px;
                bottom: -5px;
                left: 5px;
                background-color: $primary-color;
            }
        }
        // p {
        //     color: $grey;
        //     font-size: 1.2rem;
        //     line-height: 1.95rem;
        //     font-weight: 400;
        //     font-family: $secondary-font;
        // }
    }
}

.timeline-sec {
    background-color: $black;
    padding: 3rem 0;
    // background-image: linear-gradient(to right, rgba(68, 68, 68, 0.61), rgba(68, 68, 68, 0.41)),
    // url("../images/about/timelinebg.jpg");
    // background-position: center;
    // background-size: cover;

    .vertical-timeline-element-content p{
        font-family: $secondary-font;
    }
}

// .timeline {
//     position: relative;
//     max-width: 1200px;
//     margin: 100px auto;
//     // border: 4px solid red;

//     &::after {
//         position: absolute;
//         content: "";
//         width: 6px;
//         height: 100%;
//         top: 0;
//         left: 50%;
//         background-color: $white;
//         border-radius: 5px;
//         margin-left: -3px;
//         z-index: 5;
//         animation: moveline 6s linear forwards;
//     }

//     @keyframes moveline {
//         0% {
//             height: 0;
//         }
//         100% {
//             height: 100%;
//         }
//     }

//     .left-container {
//         left: 0;
//     }
//     .right-container {
//         left: 50%;

//         img {
//             left: -20px;
//         }
//     }

//     .timeline-cont {
//         padding: 10px 50px;
//         position: relative;
//         width: 50%;
//         animation: movedown 1s linear forwards;
//         opacity: 0;
//         // background-color: rgba(0,0,0,.3);

//         @keyframes movedown {
//             0%{
//                 opacity: 1;
//                 transform: translateY(-30px);
//             }
//             100%{
//                 opacity: 1;
//                 transform: translateY(0px);
//             }
//         }

//         img {
//             position: absolute;
//             width: 50px;
//             height: 50px;
//             border-radius: 50%;
//             right: -20px;
//             top: 32px;
//             z-index: 10;
//         }

//         .text-box {
//             padding: 20px 30px;
//             background-color: $primary-color;
//             position: relative;
//             border-radius: 6px;
//             font-size: 15px;
//             box-shadow: 7px 7px 20px rgba(255, 255, 255, 0.17);

//             h2 {
//                 color: $white;
//                 font-size: 1.5rem;
//                 font-weight: 700;
//                 margin-bottom: 0;
//             }
//             small {
//                 color: $white;
//                 font-size: 1.1rem;
//                 font-family: $secondary-font;
//                 line-height: 2rem;
//             }
//             p {
//                 font-size: 1.12rem;
//                 line-height: 2rem;
//                 color: $white;
//                 font-family: $secondary-font;
//                 margin-top: 1rem;
//                 margin-bottom: 0;
//                 font-weight: 500;
//             }
//         }
//     }
// }
