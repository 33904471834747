.trust-sec {
  position: relative;
  padding: 3rem 0;
//   background-color: $black;

  .trust-bg-img {
    background-image: url("../images/about/trustbg.png");
    background-position: right;
    background-size: cover;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .trust-content {
    position: relative;
    background: #933e48;
    background-color: $primary-color;
    padding: 4rem 5rem;

    @media #{$xs-med-layout} {
      padding: 2.3rem 3.4rem;
    }

    h2 {
      color: $white;
      margin-bottom: 1rem;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        height: 3px;
        width: 75px;
        bottom: -5px;
        left: 0;
        background-color: $white;
      }
    }
    p {
      color: $white;
      // font-family: $secondary-font;
      // font-weight: 400;
      // font-size: 1.1rem;
      // line-height: 1.91rem;
      width: 90%;

      @media #{$xs-med-layout} {
        width: 100%;
      }
    }

    button {
      background-color: $white;
      border: none;
      padding: 0.65rem 1.25rem;
      // border-radius: .25rem;

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: $primary-color;
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
}
