.client-page {
    background-image: linear-gradient(to right, rgba(68, 68, 68, 0.61), rgba(68, 68, 68, 0.41)),
        url("../images/clients/background.jpg");
}

.client-page-data {
    padding: 3rem 0;
    background-color: $white;

    .sec-heading {
        h2 {
            color: $black;
        }
    }

    .client-box {
        background-color: $white;
        border: 1px solid #ccc;
        border-bottom: 6px solid $primary-color;
        border-radius: 0;
        padding: 0.95rem 0 0 0;
        cursor: pointer;
        height: 100%;
        transition: all .2s ease-in;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        &:hover{
            box-shadow: 0px 0px 5px 0px rgba(28, 36, 51, 0.15);
        }
        .img-box {
            display: flex;
            align-items: center;
            justify-content: center;
            // border: 1px solid red;
            height: 150px;

            img{
                object-fit: scale-down;
                width: 70%;
            }
        }
        .content-box {
            padding: 0 .9rem;

            h3 {
                text-align: center;
                font-weight: 500;
                color: #872934;
                margin: .75rem 0;

                a{
                    color: inherit;
                }
            }
            p{
                text-align: center;
                // font-family: $secondary-font;
                // color: $grey;
                // font-size: 1rem;
                // line-height: 1.6rem;
                font-weight: 400;
            }
        }
        .industry{
            border-top: 1px solid #ccc;

            p{
                font-weight: 600;
                color: $black;
                font-family: $secondary-font;
                text-align: center;
                font-size: 1rem;
                margin: .5rem 0;
                line-height: 1.7rem;
            }
        }
    }
}

.client-section {
    padding: 3rem 0;

    .client-content {
        h2 {
            font-size: 1.32rem;
            font-family: $ternary-font;
            position: relative;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 1rem;
            color: $grey;

            &::after {
                position: absolute;
                content: "";
                height: 1.52px;
                width: 65px;
                bottom: -6px;
                left: 0%;
                // transform: translate(-50%, -50%);
                background-color: $primary-color;
            }
        }
        h4 {
            font-size: 1.8rem;
            line-height: 2.5rem;

            span {
                color: $primary-color;
            }
        }
        p {
            font-size: 1.12rem;
            font-weight: 500;
            font-family: $secondary-font;
            line-height: 1.79rem;
            margin-top: 0.5rem;
            color: $grey;
        }
        .client-btn {
            background-color: $primary-color;
            border: none;
            border-radius: 0.25rem;
            padding: 0.5rem 1.25rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            a {
                color: $white;
                font-size: 1rem;
                font-weight: 500;
                font-family: $secondary-font;
                text-transform: uppercase;
                text-decoration: none;
            }
            .arrow-icon {
                color: $white;
            }
        }
    }

    .clients-logo {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-evenly;
        align-items: center;

        img {
            width: 150px;
            filter: grayscale(100%);
            transition: all 0.3s ease-in;

            @media #{$xs-med-layout} {
                filter: grayscale(0);
            }

            &:hover {
                transform: scale(1.07);
                filter: none;
            }
        }
    }
}
