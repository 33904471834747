.team-page-section {
    background-image: linear-gradient(to right, rgba(68, 68, 68, 0.51), rgba(68, 68, 68, 0.51)),
        url("../images/page-heading.jpg");

   
}

.team-member {
    padding: 4rem 0;
    background-color: $black;

    .sec-heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            font-size: 2rem;
            color: $white;
            text-transform: uppercase;
            line-height: 2.5rem;
            text-align: center;
            font-weight: 600;
            position: relative;
            margin-bottom: 1rem;

            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 75px;
                bottom: -10px;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $primary-color;
            }
        }
        p{
            color: $white;
            font-family: $secondary-font;
            font-size: 1.1rem;
            font-weight: 400;
            line-height: 1.5rem;
        }
    }

    .team-box {
        // border-radius: 0.25rem;
        overflow: hidden;
        background-color: #f3f3f3;
        height: 475px;
        border-bottom: 4px solid $ternary;
        cursor: pointer;
        position: relative;

        .img-box {
            width: 100%;
            height: 425px;
            max-height: 300px;
            overflow: hidden;
            // border: 2px solid green;
            transition: all .5s ease-in;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: grayscale(1);
            }
        }
        .content-box {
            padding: 1rem;
            height: 75px;
            // border: 2px solid red;
            transition: all .5s ease-in;

            h3 {
                font-size: 1.12rem;
                font-weight: 600;
                color: $black;
                margin-bottom: 0.5rem;

                span {
                    font-size: 0.9rem;
                    font-weight: bold;
                    color: $primary-color;
                }
            }
            p {
                font-size: 0.9rem;
                // font-weight: 600;
                // line-height: 1.5rem;
                // font-family: $secondary-font;
                // color: #4d4d4d;
            }
           
            .social-box {
                display: flex;
                gap: 0.35rem;

                a {
                    text-decoration: none;
                    color: #ab6971;
                    transition: all 0.01s;

                    .team-social-icon {
                        font-size: 2rem;
                    }

                    &:hover {
                        transform: scale(1.05);
                        color: $primary-color;
                    }
                }
            }
        }

        &:hover {
            box-shadow: 2px 2px 10px 0px $ternary;

            .img-box{
                height: 125px;
            }

            .content-box{
                height: 300px;
            }
        }
    }

    .team-btn {
        display: flex;
        justify-content: center;

        button {
            background-color: $primary-color;
            border: none;
            // border-radius: 0.35rem;
            padding: 0.5rem 1.2rem;
            margin-top: 0.42rem;

            a {
                text-decoration: none;
                color: $white;
                font-size: 1.1rem;
                text-transform: uppercase;
                font-family: $secondary-font;
                font-weight: 500;
                // letter-spacing: 1px;
            }
        }
    }
}
