.resource-page-section {
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.61),
      rgba(68, 68, 68, 0.41)
    ),
    url("../images/resources/resourcebg.jpg");
}

.search-box {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 8px 12px;
  margin: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input[type="text"] {
    border: none;
    width: 100%;
  }
}

.resource-list-card {
  box-shadow: 0 3px 5px rgb(85 85 85 / 20%);
  border-radius: 0.35rem;
  overflow: hidden;
  transition: all .3s ease-in;
  cursor: pointer;
  height: 100%;

  &:hover{
    box-shadow: 8px 10px 12px rgba(0,0,0,.25),0 -1px 1px rgba(0,0,0,.13);

    img{
        transform: scale(1.1);
    }
  }

  .img-div {
    width: 100%;
    height: 250px;
    overflow: hidden;
    overflow: hidden;

    img {
      border-radius: 0;
    }
  }
  .service-type {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    color: $ternary;
    font-family: $secondary-font;
  }
  .service-name{
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: $primary-color;
    margin-bottom: .21rem;
    letter-spacing: 0;

    a{
        color: $primary-color;
    }
  }

  .service-description{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .know-more{
    color: $primary-color;
    font-size: 1rem;
    font-weight: 600;
    font-family: $secondary-font;
  }
}

.resourcedata{
    .res-img-div{
        border-radius: .5rem;
        overflow: hidden;

        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .resource-name{
        color: $primary-color;
        font-weight: 600;
        font-size: 1.95rem;
        margin-bottom: 1rem;
    }

    .page-content {
        .page-sub-heading {
            margin-top: 2rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
  
            .heading-icon {
                font-size: 1.1rem;
                color: $primary-color;
            }
  
            h4 {
                // text-transform: uppercase;
                font-size: 2rem;
                color: $primary-color;
                font-weight: 600;
                margin-bottom: 0;
                text-decoration: underline;
            }
        }
        .sub-heading-content {
            padding-inline-start: 0.75rem;
  
            .sub-heading-heading {
                font-size: 1.25rem;
                color: $black;
                font-weight: 600;
                font-family: $secondary-font;
                margin-bottom: 0.4rem;
            }
            ol,
            ul {
                li {
                    font-family: $secondary-font;
                    color: $grey;
                    font-weight: 500;
                    font-size: 1.1rem;
                }
            }
            ul {
                list-style: disc;
            }
            b {
                color: $black;
            }
        }
    }
}