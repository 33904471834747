.page-header {
    margin-top: -7rem;
    padding: 3rem 0 0;
    height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    align-items: flex-end;

    @media #{$xs-med-layout} {
        margin-top: -7rem;
        background-position: left;
    }

    .page-heading {
        h2 {
            color: $white;
            font-size: 3rem;
            font-weight: 600;
            line-height: 2rem;
            margin-bottom: 1rem;
            position: relative;
            text-transform: uppercase;

            @media #{$xs-med-layout} {
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }

            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 75px;
                bottom: -15px;
                left: 0.5%;
                background-color: $primary-color;
            }
        }
    }
}

.sec-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        font-size: 2rem;
        color: $white;
        text-transform: uppercase;
        line-height: 2.5rem;
        text-align: center;
        font-weight: 600;
        position: relative;
        margin-bottom: 1rem;

        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 75px;
            bottom: -10px;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $primary-color;
        }
    }
    p{
        color: $white;
        font-family: $secondary-font;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.75rem;
    }
}