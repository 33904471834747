.career-page-section {
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.61),
      rgba(68, 68, 68, 0.41)
    ),
    url("../images/career/bgimg.jpg");

    @media #{$xs-med-layout}{
      background-position: center;
    }
  // background-position: top;
}

.career-inner {
  padding: 4rem 0;
  background-color: rgba(135, 41, 52, 0.1);

  .job-opp {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 1.85rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0.51rem;
      font-family: $secondary-font;
      color: $primary-color;
    }

    // p {
    //   font-family: $secondary-font;
    //   color: $grey;
    //   font-size: 1.1rem;
    //   line-height: 1.75rem;
    // }
    ul,
    ol {
      li {
        font-family: $secondary-font;
        color: $grey;
        font-size: 1.1rem;
        line-height: 1.75rem;
        margin-bottom: 0.5rem;

        b {
          color: $black;
          font-weight: bolder;
          font-family: $secondary-font;
        }
      }
    }
  }
}

.joinus {
  background-color: $black;
  padding: 4rem 0;

  .data-boxes {
    display: grid;
    grid-template-columns: auto auto;
    align-self: center;
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media #{$xs-layout}{
      // grid-template-columns: 99%;
    }

    .content-box {
      background-color: $white;
      border-radius: 1rem;
      padding: 1rem;
      cursor: pointer;
      transition: transform 0.43s;
      width: 48%;

      @media #{$xs-layout}{
        // grid-template-columns: 99%;
        width: 100%;
      }
      // box-shadow: 2px 2px 10px 0px $ternary;

      &:hover {
        transform: translateY(-.51rem) scale(1.03);
        box-shadow: 2px 2px 10px 0px $ternary;
      }

      h1 {
        font-size: 1.25rem;
        font-weight: 600;
        color: $ternary;
        font-family: $secondary-font;
        margin-bottom: 0.5rem;

        span {
          color: $primary-color;
          margin-right: 0.25rem;
        }
      }
      // p{
      //   font-size: 1.1;
      // }
    }
    // .box3 {
    //   width: 50%;
    //   grid-column: 1 / 3; /* Spans across both columns */
    //   justify-self: center; /* Centers the box horizontally */
    // }
  }
  .img-box{
      width: 100%;
      height: 100%;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      // align-items: center;
  }
}

.process {
  padding: 4rem 0;
  background-color: $white;

  .sect-heading {
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $primary-color;
  }

  .process-content {
    p {
      font-family: $secondary-font;
      font-size: 1.1rem;
      line-height: 1.7rem;
      color: $grey;
    }

    .step-1 {
      background-color: #f2ece1;
    }
    .step-2 {
      background-color: #e6defa;
    }
    .step-3 {
      background-color: #e7d4d6;
    }
    .step-4 {
      background-color: #faebcb;
    }
    .step-5 {
      background-color: #cdf0ed;
    }

    .steps {
      padding: 1.5rem;
      border-radius: 1rem;
      margin-bottom: 1rem;

      .stepno {
        font-family: $secondary-font;
        background-color: $ternary;
        padding: 0.45rem 1.5rem;
        font-weight: 600;
        font-size: 1.21rem;
        color: $white;
        width: fit-content;
        border-radius: 2rem;
        margin-bottom: 0.75rem;
      }
      .step-heading {
        font-family: $secondary-font;
        font-weight: 600;
        color: $primary-color;
        font-size: 1.2rem;
        line-height: 1.75rem;
        padding-inline-start: 0.2rem;
        margin-bottom: 0.5rem;
      }

      ul,
      ol {
        list-style: none;
        padding-inline-start: .2rem;

        li {
          font-family: $secondary-font;
          color: #4d4d4d;
          font-size: 1.1rem;
          line-height: 1.75rem;
          margin-bottom: 0.5rem;

          b {
            color: $black;
            font-family: $secondary-font;
            font-weight: bolder;
          }
        }
      }
    }
  }
}
