.footer {
  background-color: $black;
  // padding: 42px 0 0 0;

  .subscription-box {
    background-color: $white;
    padding: 2.5rem 0 1rem;
    border-bottom: 2px solid $white;
    border-top: 2px solid $primary-color;


    .subs-text {
      p {
        color: $black;
        font-family: $secondary-font;
        font-size: 1.29rem;
        line-height: 1.75rem;
        width: 70%;

        @media #{$xs-med-layout} {
          width: 100%;
          text-align: center;
        }
      }
    }
    .subs-heading {
      text-align: center;
      h2 {
        font-size: 2.3rem;
        color: $black;
        line-height: 2rem;
        margin-bottom: 0.5rem;
      }
      p {
        color: $black;
        font-size: 1.2rem;
        line-height: 1.5rem;
        letter-spacing: 1px;
        font-family: $secondary-font;
      }
    }
    .subs-form {
      width: 100%;
      .form-div {
        width: 100%;
        height: 48px;
        display: flex;

        .form-box {
          flex: 2;
        }

        .form-control {
          background-color: #ebebeb;
          border: none;
          outline: none;
          border-radius: 0;
          color: $black;
          height: 100%;
          font-size: 1rem;
          font-family: $secondary-font;
        }
        .subs-submit-btn {
          background-color: $primary-color;
          color: $white;
          border: none;
          border-radius: 0;
          font-size: 1.1rem;
          font-weight: 500;
          flex: 1;
        }
      }
    }
  }

  .footer-top {
    border-bottom: 2px solid #000;
    padding: 3rem 0 2rem;

    ul {
      list-style: none;
      padding: 0;
    }
  }

  a {
    padding: 0.5rem 0;
  }
}
.footer-col {
  padding-right: 2rem;

  @media #{$xs-layout} {
    margin-bottom: 1rem;
  }
  .main-heading {
    color: $primary-color;
    // margin-bottom: 1.8rem;
    text-decoration: none;
    font-size: 1.3rem;
    line-height: 15px;
    font-weight: 600;
  }

  .footer-para {
    color: $white;
    // font-family: $secondary-font;
    padding: 0;
  }
  .text-call {
    color: $white;
    font-family: $secondary-font;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
  }

  .footer-call-icon {
    color: $primary-color;
    font-size: 17px;
    margin-right: 7px;
  }

  h4 {
    margin: 0px 0px 1.25rem 0px;
    text-align: left;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: $primary-font;
    line-height: 1em;
    letter-spacing: 1px;
  }

  .address-li {
    display: flex;
    align-items: flex-start;
    gap: 2px;
  }

  ul {
    li {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 0;
      }
      a {
        transition: all 0.2s ease-in;
        &:hover {
          color: $white;
          padding-left: 5px;
          opacity: 1;
          font-weight: 600;
        }
      }
      a,
      p {
        // text-transform: capitalize;
        text-decoration: none;
        padding: 0.5rem 0;
        display: block;
        transition: all 0.4s ease;
        font-size: 0.9rem;
        font-weight: 500;
        color: $white;
        opacity: 0.75;
        font-style: normal;
        font-family: $secondary-font;
      }
    }
  }

  .social-links {
    display: flex;

    a {
      font-size: 1.2rem;
      margin: 0 0.75rem 0.75rem 0;
      text-align: center;
      line-height: 40px;
      padding: 10px;

      align-items: center;
      border: 2px solid $white;
      color: $white;
      display: flex;
      height: 40px;
      justify-content: center;
      // -webkit-transform: skewX(15deg);
      // transform: skewX(15deg);
      transition: 0.2s ease-in;
      width: 40px;

      &:hover {
        border-color: $primary-color;
        color: $primary-color;
        transform: skewX(0);
      }
    }
  }

  .appointment-box {
    .appoint-btn {
      font-size: 0.81rem;
      font-weight: 600;
      font-family: $secondary-font;
      text-decoration: none;
      line-height: 1.25rem;
      color: $primary-color;
      text-transform: uppercase;
      // letter-spacing: 1px;
      background-color: transparent;
      border: 2px solid $primary-color;
      padding: 0.75rem 1.25rem;
      border-radius: 1.5rem;
      transition: all 0.3e ease-in;

      &:hover {
        background-color: $ternary;
        color: $white;
        box-shadow: 0 0 7px rgba(255, 255, 255, 0.46);
      }
    }
  }
}

.contact-box {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin: 0.5rem 0;

  &:not(:nth-child(1)) {
    align-items: center;
  }

  .contact-icon {
    color: $primary-color;
    font-size: 0.8rem;
    border: 1px solid $primary-color;
    border-radius: 100%;
    padding: 0.5rem;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 20px;
    transition: all 0.1s;
  }

  .contact-details {
    width: 90%;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0;
    }

    a {
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.footer-bottom {
  padding: 1rem 0;
  border-top: 1px solid $white;
  background-color: $black;

  p {
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
  }

  .text-reserve {
    text-align: left;

    @media #{$xs-med-layout} {
      text-align: center;
    }
  }

  ul {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    list-style: none;

    @media #{$xs-med-layout} {
      justify-content: center;
    }

    li {
      font-size: 1rem;
      margin: 0 0.45rem;
      position: relative;

      a {
        text-decoration: none;
      }

      &:not(:last-child) {
        &:before {
          position: absolute;
          content: "|";
          top: 4px;
          width: 4px;
          height: 8px;
          color: $ternary;
          right: -8px;
        }
      }
    }
  }
}

.mailing-popup{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .closed{
        position: absolute;
        border: none;
        outline: none;
        width: 2rem;
        height: 2rem;
        background-color: #e7d4d6;
        color: $primary-color;
        font-size: 1.38rem;
        font-weight: 600;
        border-radius: 5rem;
        top: 0;
        right: 0;
        transform: rotate(45deg);
        transition: all .2s;

        &:hover{
            transform: scale(1.08);
        }
    }

    img{
        width: 100px;
    }
    h3{
        font-weight: 600;
        color: $primary-color;
        font-size: 1.75rem;
        line-height: 2rem;
        margin-top: 1.5rem;
    }
    p{
        font-size: 1.1rem;
        line-height: 1.75rem;
        font-weight: 500;
        text-align: center;
    }
}