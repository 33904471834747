@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

// $primary-font: "Cardo", serif;
// $secondary-font: "Lato", sans-serif;

$primary-font: "Lexend Deca", sans-serif;
$secondary-font: "Noto Sans", sans-serif;
$ternary-font: "Montserrat", sans-serif;

$primary-color: #e46004; // Marron #E36006 872934
$secondary-color : #D1CEC4;  // Light silver
$ternary: #050926;  // beige  #050926 BEA268
// $action-color: #B4762D;  // Light Brown
$black: #212121; /// 0D0C0A
$white: #ffffff;
$grey: #6d6e7b;   /// #64666C

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout-1500: "only screen and (max-width: 1500px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$lg-md-layout: "only screen and (max-width: 1024px)";
$lg-med-layout: "only screen and (max-width: 991px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 992px)";
$xs-layout: "only screen and (max-width: 767px)";
$xs-med-layout: "only screen and (max-width: 576px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
$xxs-md-layout: "only screen and (max-width: 425px)";